<template>
  <CRow>
    <CCol :class="[$store.state.authorized ? '' : 'm-5']">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol class="text-left"><h2>Довідка</h2></CCol>
            <CCol v-if="isAdmin" class="text-right">
              <CButton color="secondary" @click.prevent="addNotice()">Додати</CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <template v-if="answers.length <=1">
            <CRow class="justify-content-center">
              <CSpinner color="primary" size="xl" style="width: 8rem;height: 8rem; font-size:20px;"/>
            </CRow>
          </template>
          <span v-if="answers.length >1">
            <span v-for="answer in answers" v-bind:key="answer.id">
              <CListGroup>
                  <CButton
                      @click.prevent="toggleCollapse(answer.name)"
                      class="w-100 p-0 text-left mb-2"
                  >
                    <CListGroupItem href="#" color="secondary">
                      <CRow>
                        <CCol col="6" sm="8" md="9" lg="10" xl="10">
                          {{ answer.name }}
                        </CCol>
                        <CCol col="6" sm="4" md="3" lg="2" class="text-right">
                          <CBadge class="p-1" :color="getBadgeStatusTender(answer.is_privatbank)">
                            {{ answer.is_privatbank ? `Для працівників` : `` }}
                          </CBadge>
                        </CCol>
                      </CRow>
                    </CListGroupItem>

                  </CButton>
                <CCollapse :show="showName === answer.name" class="px-0 border-0">

                  <template v-if="isAdmin">
                    <CRow class="justify-content-end pr-3">
                      <span class="mt-1 mr-1">Тільки для працівників банку: </span>
                      <!--                      <CButton class="p-1 mb-1 ml-1 small" :color="answer.is_privatbank ? 'warning': 'primary'"  @click.prevent="showTenderAdmin(answer.id, !answer.is_privatbank)">{{answer.is_privatbank ? `Так`: `Ні`}}</CButton>-->
                      <CSwitch
                          label-off="Ні"
                          label-on="Так"
                          color="primary"
                          variant="opposite"
                          :checked.sync="answer.is_privatbank"
                          @update:checked="showTenderAdmin(answer.id, answer.is_privatbank)"
                      />

                      <CButton class="p-1 mb-1 ml-2 small" color="success" @click.prevent="editNotice(answer.id)">Змінити</CButton>
                      <CButton class="p-1 mb-1 ml-2 small" color="danger" @click.prevent="removeNotice(answer.id)">Видалити</CButton>
                    </CRow>
                  </template>
                  <CCard body-wrapper class="border-0">
                    <div class="panel-body">
                      <CSpinner v-if="!answer.message" size="xl" color="primary"/>
                      <p v-else v-html="answer.message"></p>
                    </div>
                  </CCard>
                </CCollapse>
              </CListGroup>
            </span>
          </span>

        </CCardBody>
      </CCard>
    </CCol>


    <modal-text-editor
        v-if="showModalTextEditor"
        :show-editor="showModalTextEditor"
        :edit-id="editId"
        @updateFAQ="getUpdateFaqANDShowID()"
        @close="closeModal"
    />
  </CRow>
</template>


<script>
import axios from 'axios'
import {can} from "@/rules/permissions";
// import ModalTextEditor from "../Components/Modal/ModalTextEditor";
// import Editor from '@tinymce/tinymce-vue'
// import 'tinymce/tinymce'
// import 'tinymce/icons/default'

export default {
  name: "FAQ",
  data: () => {
    return {
      showModalTextEditor: false,

      // showEditor: false,
      collapse: false,
      showName: 0,
      answers: [{
        id: 0,
        name: "",
        message: "",
        is_privatbank: false,
      }],
      editId: null,
      is_privatbank: false,


    }
  },
  components: {
    ModalTextEditor: () => import("../Components/Modal/ModalTextEditor")
  },
  methods: {
    can,
    getUpdateFaqANDShowID(name) {
      console.log(name)
      this.getAnswers();
      this.showName = name;
    },

    getBadgeStatusTender(status) {
      return status ? 'success' : '';
    },

    removeNotice(id) {
      if (!confirm("Ви впевненні, що бажаєте видалити запис?")) {
        return
      }
      let self = this;
      axios.delete(`/api/faq/${id}`).then(function () {
        self.getAnswers();
      }).catch(function () {
      });
    },

    showTenderAdmin(id, status) {
      // if(!confirm("Ви впевненні, що бажаєте сховати цей запис?")){
      //   return
      // }
      let self = this;
      axios.put(`/api/faq/${id}/show?is_privatbank=${status}`).then(function () {
        self.getAnswers();
      }).catch(function () {
        self.getAnswers();
      });
    },
    editNotice(id) {
      this.showModalTextEditor = true;
      this.editId = id;
    },

    toggleCollapse(name) {
      if (this.showName === name) {
        this.showName = "";
        return;
      }
      this.showName = name;
    },

    closeModal() {
      this.showModalTextEditor = false;
      this.editId = null;
      console.log('close')
    },

    getAnswers: function () {
      let self = this;
      axios.get(`/api/faq?brief`)
          .then(function (response) {
            // console.log(response)
            self.$set(self, "answers", response.data);
            self.getMessageAnswer();
          })
          .catch(function () {
          });
    },

    getMessageAnswer() {
      let self = this;
      axios.get(`/api/faq`).then(response => {
        console.log(response.data)
        self.$set(self, "answers", response.data);
        // self.answers.forEach(item => item.message = response.data.includes(item.id).message)
      })
    },

    addNotice: function () {
      // this.editorContent = "";
      // this.editorHeader = "";
      this.showModalTextEditor = true;
      this.editId = null;
    },

    funcShowFAQ() {
      this.getAnswers();
    },
  },
  computed: {
    isAdmin() {
      return can('bank_user') && (can('admin_change_data_page_faq') || can('head_change_data_page_faq'));
    },
    currentPage() {
      return this.$route.path;
    }
  },
  created() {
    this.funcShowFAQ();
    this.showName = decodeURIComponent(new URLSearchParams(window.location.search).get("q"));
  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.funcShowFAQ();
    },
  }
}
</script>
